.pageBackground {
  background-image: url('https://hophacks-image.s3.us-east-1.amazonaws.com/hophacks_title_layers.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.textHeaderContainer {
  position: absolute;
  left: 51%;
  top: 54%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: max-content;
}

.textHeader {
  font-family: Anton, sans-serif;
  font-weight: 400;
  font-size: 140px;
  line-height: 120px;
  letter-spacing: 5%;
  color: #ffffff;
  text-transform: uppercase;
  white-space: pre-line;
}

.larger-letter {
  font-size: 1.1em; /* Slightly larger than the parent */
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  text-transform: inherit;
}

.hophacks-logo {
  position: absolute;
  top: -40px;
  left: 195px;
  width: 200px;
  height: auto;
  z-index: 1;
}

.textSubtextContainer {
  position: absolute;
  left: 61.5%;
  top: 77%;
  transform: translate(-50%, -50%);
  text-align: left;
}

.textSubtext {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 43px;
  line-height: 100px;
  letter-spacing: 5%;
  color: #ffe188;
  text-transform: uppercase;
}

.detailsContainer {
  position: absolute;
  left: 64%;
  top: 83%;
  transform: translate(-50%, -50%);
  width: max-content;
}

.details {
  font-family:
    Open Sans,
    sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 100px;
  letter-spacing: 5%;
  color: #ffffff;
}

.discordbtnContainer {
  position: absolute;
  left: 140%;
  top: 79%;
  transform: translate(-50%, -50%);
  width: max-content;
}

.discordbtn {
  font-family:
    Open Sans,
    sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: normal;
  letter-spacing: 5%;
  color: #ffffff;
}

.join-discord {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: all 300ms ease-in-out;
  text-shadow: 0 0 8px #facc15;
  border: none;
  cursor: pointer;
}

.join-discord:hover {
  text-shadow: 0 0 12px #facc15;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .textHeaderContainer {
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }

  .textHeader {
    font-size: 80px;
    line-height: 90px;
    letter-spacing: 2%;
  }

  .hophacks-logo {
    top: -140px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
  }

  .textSubtextContainer {
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .textSubtext {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1%;
  }

  .detailsContainer {
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
  }

  .details {
    font-size: 20px;
    line-height: normal;
    letter-spacing: 1%;
  }

  .discordbtnContainer {
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
  }

  .discordbtn {
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
  }
}

.card-front,
.alumni-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 5px;
}

.alumni-card-back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('https://hophacks-image.s3.us-east-1.amazonaws.com/logo2023.PNG');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
}

.flip-button-back {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.east-icon-back {
  color: #e3e3e3;
  font-size: 30px;
  transition:
    color 0.3s ease,
    transform 0.3s ease;
}

.flip-button-back:hover .east-icon-back {
  color: #0077b5;
  transform: scale(1.1);
}
